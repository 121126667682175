import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.png';

function Header() {
  const auth = sessionStorage.getItem('token');

  const logout = () => {
    sessionStorage.removeItem('token');
    window.location.reload();
  }
  
return (
<header className="munyori-row header">
<Link to={"/"} className="logo munyori-col s4"><img src={ logo } alt="MUNYORI" style={{height: "20px", width: "auto"}}/></Link>
<Link to={"/"} className="munyori-col s2 munyori-center"><i className="fas fa-comment"></i><span className="munyori-hide-small munyori-hide-medium"> CHAT</span></Link>
<Link to={"/settings/"} className="munyori-col s2 munyori-center"><i className="fas fa-cog"></i><span className="munyori-hide-small munyori-hide-medium"> SETTINGS</span></Link>
<Link to={"/recharge/"} className="munyori-col s2 munyori-center"><i className="fas fa-credit-card"></i><span className="munyori-hide-small munyori-hide-medium"> RECHARGE</span></Link>
{auth
? <Link onClick={ logout } to={"/auth/"} className="munyori-red munyori-hover-orange munyori-col s2 munyori-center"><span className="munyori-hide-small munyori-hide-medium">LOGOUT </span><i className="fas fa-sign-out"></i></Link>
: <Link to={"/auth/"} className="munyori-green munyori-hover-orange munyori-col s2 munyori-center"><span className="munyori-hide-small munyori-hide-medium">LOGIN </span><i className="fas fa-sign-in"></i></Link>
}
</header>
  );
}

export default Header;