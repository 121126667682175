import React, { useState, useEffect } from 'react';
import Greeting from '../includes/greeting';
import { Link } from "react-router-dom";
import API from '../../jsx/backend';
import Loader from '../includes/loader';
import SideBar from '../sections/sidebar';
import Top from '../includes/topping';
import {Helmet} from "react-helmet";

const Auth = () => {
  const [surname, setSurname] = useState('');
  const auth = sessionStorage.getItem('token');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [interest, setInterest] = useState('');
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('Unprocessed');
  const [auther, setAuth] = useState(true);
  const [pop, setPop] = useState(false);
  const [done, setDone] = useState(false);

  const [processing, setProcessing] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setProcessing(true);
    const loginFormDataNew = new FormData();
    loginFormDataNew.append("email", email);
    loginFormDataNew.append("password", password);
    API.post(`/query/login/`, loginFormDataNew)
    .then((response) => {
      setSuccess(response.data.status);
      setDone(true);
      setProcessing(false);
      setMessage(response.data.message)
      if(response.data.token.length > 0){
      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('name', response.data.name);
      sessionStorage.setItem('instruction', response.data.instruction);
      window.location.href = "/";
      }})
.catch((error) => {
setProcessing(false);
setDone(true);
})
};

  const handleSignUp = (e) => {
  e.preventDefault();
  setProcessing(true);
  const signupFormDataNew = new FormData();
  signupFormDataNew.append("name", name);
  signupFormDataNew.append("surname", surname);
  signupFormDataNew.append("country", country);
  signupFormDataNew.append("email", email);
  signupFormDataNew.append("password", password);
  signupFormDataNew.append("interest", interest);

  API.post(`/query/signup/`, signupFormDataNew)
  .then((response) => {
        setSuccess(response.data.status);
        setDone(true);
        setProcessing(false);
        setMessage(response.data.message)
      })
  .catch((error) => {
  setProcessing(false);
  setDone(true);
  })
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setProcessing(true);
    const resetFormDataNew = new FormData();
    resetFormDataNew.append("email", email);
    API.post(`/query/reset/`, resetFormDataNew)
    .then((response) => {
      switch(response.data.status){
        case "1":
          setSuccess(true);
          setDone(true);
          setProcessing(false);
          setMessage(response.data.message)
          break;
  
          default:
          setSuccess(false);
          setProcessing(false);
          setDone(true);
          setMessage(response.data.message)
      }})
    .catch((error) => {
    setProcessing(false);
    setDone(true);
    })
    };

  const handleAuth = () => {
    setAuth(!auther);
  };
  const handlePop = () => {
    setPop(!pop);
  };
  const reset = () => {
    setDone(false);
  };
return (
<div  style={{minHeight: "95VH", paddingTop: "60px", wordWrap : "break-word"}}>
<Helmet>
<title>Authentication Required -  Munyori</title>
</Helmet>
<div className="munyori-section">
<div className="munyori-container munyori-row-padding">
{!auth
? <div className="munyori-twothird">
{done
? <>
{success
? <div onClick={ reset } className="munyori-pale-green munyori-padding munyori-center" style={{borderRadius: "10px"}}>{ message }</div>
: <div className="munyori-pale-red munyori-padding munyori-center" style={{borderRadius: "10px"}}>{ message } <br /> <hr /> <br /> <span onClick={ reset } style={{cursor: "pointer"}}>RETRY</span></div>
}
</>
:<>

{processing
? <Loader />
:<div className="container-auth">
  {auther
  ?<form onSubmit={ handleLogin}>
			<div className="munyori-container munyori-center">
				<h4>MUNYORI LOGIN</h4>
			</div>
      <hr />
			<div className="munyori-row-padding">
				<div className="munyori-half">
					<label htmlFor="email"><b>Email</b></label>
					<input type="email" placeholder="Enter Email" name="password" value={email} onChange={(e) => setEmail(e.target.value)} required />
				</div>
				<div className="munyori-half">
					<label htmlFor="password"><b>Password</b></label>
					<input type="password" placeholder="Enter Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
				</div>
			</div>
			<button type="submit">LOGIN</button>
			<div className="munyori-row-padding">
				<div className="munyori-half">
					<button onClick={ handlePop } type="button" className="munyori-pale-red" style={{width: "100%"}}>RESET PASSWORD</button>
				</div>
				<div className="munyori-half">
					<button onClick={ handleAuth } type="button" style={{width: "100%"}} className="munyori-pale-green">SIGNUP</button>
				</div>
			</div>
		</form>
  : <form onSubmit={ handleSignUp }>
  <div className="munyori-container munyori-center">
    <h4>MUNYORI SIGNUP</h4>
  </div>
  <hr />
  <div className="munyori-row-padding">
    <div className="munyori-half">
      <label htmlFor="username"><b>Email</b></label>
      <input type="email" placeholder="Enter Email" name="username" value={email} onChange={(e) => setEmail(e.target.value)} required />
    </div>
    <div className="munyori-half">
      <label htmlFor="password"><b>Password</b></label>
      <input type="password" placeholder="Create Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
    </div>
  </div>
  <br />
  <div className="munyori-row-padding">
    <div className="munyori-half">
      <label htmlFor="name"><b>Name</b></label>
      <input type="text" autocomplete="off" placeholder="Enter Name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
    </div>
    <div className="munyori-half">
      <label htmlFor="surname"><b>Surname</b></label>
      <input type="text" autocomplete="off" placeholder="Enter Name" name="surname" value={surname} onChange={(e) => setSurname(e.target.value)} required />
    </div>
  </div>
  <br />
  <div className="munyori-row-padding">
    <div className="munyori-half">
      <label htmlFor="name"><b>Country</b></label>
      <select name="country" value={country} onChange={(e) => setCountry(e.target.value)} required>
      <option value="">Select Country</option>
      <option value="Afghanistan">Afghanistan</option>
    <option value="Aland Islands">Åland Islands</option>
    <option value="Albania">Albania</option>
    <option value="Algeria">Algeria</option>
    <option value="American Samoa">American Samoa</option>
    <option value="Andorra">Andorra</option>
    <option value="Angola">Angola</option>
    <option value="Anguilla">Anguilla</option>
    <option value="Antarctica">Antarctica</option>
    <option value="Antigua and Barbuda">Antigua & Barbuda</option>
    <option value="Argentina">Argentina</option>
    <option value="Armenia">Armenia</option>
    <option value="Aruba">Aruba</option>
    <option value="Australia">Australia</option>
    <option value="Austria">Austria</option>
    <option value="Azerbaijan">Azerbaijan</option>
    <option value="Bahamas">Bahamas</option>
    <option value="Bahrain">Bahrain</option>
    <option value="Bangladesh">Bangladesh</option>
    <option value="Barbados">Barbados</option>
    <option value="Belarus">Belarus</option>
    <option value="Belgium">Belgium</option>
    <option value="Belize">Belize</option>
    <option value="Benin">Benin</option>
    <option value="Bermuda">Bermuda</option>
    <option value="Bhutan">Bhutan</option>
    <option value="Bolivia">Bolivia</option>
    <option value="Bonaire, Sint Eustatius and Saba">Caribbean Netherlands</option>
    <option value="Bosnia and Herzegovina">Bosnia & Herzegovina</option>
    <option value="Botswana">Botswana</option>
    <option value="Bouvet Island">Bouvet Island</option>
    <option value="Brazil">Brazil</option>
    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
    <option value="Brunei Darussalam">Brunei</option>
    <option value="Bulgaria">Bulgaria</option>
    <option value="Burkina Faso">Burkina Faso</option>
    <option value="Burundi">Burundi</option>
    <option value="Cambodia">Cambodia</option>
    <option value="Cameroon">Cameroon</option>
    <option value="Canada">Canada</option>
    <option value="Cape Verde">Cape Verde</option>
    <option value="Cayman Islands">Cayman Islands</option>
    <option value="Central African Republic">Central African Republic</option>
    <option value="Chad">Chad</option>
    <option value="Chile">Chile</option>
    <option value="China">China</option>
    <option value="Christmas Island">Christmas Island</option>
    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
    <option value="Colombia">Colombia</option>
    <option value="Comoros">Comoros</option>
    <option value="Congo">Congo - Brazzaville</option>
    <option value="Congo, Democratic Republic of the Congo">Congo - Kinshasa</option>
    <option value="Cook Islands">Cook Islands</option>
    <option value="Costa Rica">Costa Rica</option>
    <option value="Cote D'Ivoire">Côte d’Ivoire</option>
    <option value="Croatia">Croatia</option>
    <option value="Cuba">Cuba</option>
    <option value="Curacao">Curaçao</option>
    <option value="Cyprus">Cyprus</option>
    <option value="Czech Republic">Czechia</option>
    <option value="Denmark">Denmark</option>
    <option value="Djibouti">Djibouti</option>
    <option value="Dominica">Dominica</option>
    <option value="Dominican Republic">Dominican Republic</option>
    <option value="Ecuador">Ecuador</option>
    <option value="Egypt">Egypt</option>
    <option value="El Salvador">El Salvador</option>
    <option value="Equatorial Guinea">Equatorial Guinea</option>
    <option value="Eritrea">Eritrea</option>
    <option value="Estonia">Estonia</option>
    <option value="Ethiopia">Ethiopia</option>
    <option value="Falkland Islands (Malvinas)">Falkland Islands (Islas Malvinas)</option>
    <option value="Faroe Islands">Faroe Islands</option>
    <option value="Fiji">Fiji</option>
    <option value="Finland">Finland</option>
    <option value="France">France</option>
    <option value="French Guiana">French Guiana</option>
    <option value="French Polynesia">French Polynesia</option>
    <option value="French Southern Territories">French Southern Territories</option>
    <option value="Gabon">Gabon</option>
    <option value="Gambia">Gambia</option>
    <option value="Georgia">Georgia</option>
    <option value="Germany">Germany</option>
    <option value="Ghana">Ghana</option>
    <option value="Gibraltar">Gibraltar</option>
    <option value="Greece">Greece</option>
    <option value="Greenland">Greenland</option>
    <option value="Grenada">Grenada</option>
    <option value="Guadeloupe">Guadeloupe</option>
    <option value="Guam">Guam</option>
    <option value="Guatemala">Guatemala</option>
    <option value="Guernsey">Guernsey</option>
    <option value="Guinea">Guinea</option>
    <option value="Guinea-Bissau">Guinea-Bissau</option>
    <option value="Guyana">Guyana</option>
    <option value="Haiti">Haiti</option>
    <option value="Heard Island and Mcdonald Islands">Heard & McDonald Islands</option>
    <option value="Holy See (Vatican City State)">Vatican City</option>
    <option value="Honduras">Honduras</option>
    <option value="Hong Kong">Hong Kong</option>
    <option value="Hungary">Hungary</option>
    <option value="Iceland">Iceland</option>
    <option value="India">India</option>
    <option value="Indonesia">Indonesia</option>
    <option value="Iran, Islamic Republic of">Iran</option>
    <option value="Iraq">Iraq</option>
    <option value="Ireland">Ireland</option>
    <option value="Isle of Man">Isle of Man</option>
    <option value="Israel">Israel</option>
    <option value="Italy">Italy</option>
    <option value="Jamaica">Jamaica</option>
    <option value="Japan">Japan</option>
    <option value="Jersey">Jersey</option>
    <option value="Jordan">Jordan</option>
    <option value="Kazakhstan">Kazakhstan</option>
    <option value="Kenya">Kenya</option>
    <option value="Kiribati">Kiribati</option>
    <option value="Korea, Democratic People's Republic of">North Korea</option>
    <option value="Korea, Republic of">South Korea</option>
    <option value="Kosovo">Kosovo</option>
    <option value="Kuwait">Kuwait</option>
    <option value="Kyrgyzstan">Kyrgyzstan</option>
    <option value="Lao People's Democratic Republic">Laos</option>
    <option value="Latvia">Latvia</option>
    <option value="Lebanon">Lebanon</option>
    <option value="Lesotho">Lesotho</option>
    <option value="Liberia">Liberia</option>
    <option value="Libyan Arab Jamahiriya">Libya</option>
    <option value="Liechtenstein">Liechtenstein</option>
    <option value="Lithuania">Lithuania</option>
    <option value="Luxembourg">Luxembourg</option>
    <option value="Macao">Macao</option>
    <option value="Macedonia, the Former Yugoslav Republic of">North Macedonia</option>
    <option value="Madagascar">Madagascar</option>
    <option value="Malawi">Malawi</option>
    <option value="Malaysia">Malaysia</option>
    <option value="Maldives">Maldives</option>
    <option value="Mali">Mali</option>
    <option value="Malta">Malta</option>
    <option value="Marshall Islands">Marshall Islands</option>
    <option value="Martinique">Martinique</option>
    <option value="Mauritania">Mauritania</option>
    <option value="Mauritius">Mauritius</option>
    <option value="Mayotte">Mayotte</option>
    <option value="Mexico">Mexico</option>
    <option value="Micronesia, Federated States of">Micronesia</option>
    <option value="Moldova, Republic of">Moldova</option>
    <option value="Monaco">Monaco</option>
    <option value="Mongolia">Mongolia</option>
    <option value="Montenegro">Montenegro</option>
    <option value="Montserrat">Montserrat</option>
    <option value="Morocco">Morocco</option>
    <option value="Mozambique">Mozambique</option>
    <option value="Myanmar">Myanmar (Burma)</option>
    <option value="Namibia">Namibia</option>
    <option value="Nauru">Nauru</option>
    <option value="Nepal">Nepal</option>
    <option value="Netherlands">Netherlands</option>
    <option value="Netherlands Antilles">Curaçao</option>
    <option value="New Caledonia">New Caledonia</option>
    <option value="New Zealand">New Zealand</option>
    <option value="Nicaragua">Nicaragua</option>
    <option value="Niger">Niger</option>
    <option value="Nigeria">Nigeria</option>
    <option value="Niue">Niue</option>
    <option value="Norfolk Island">Norfolk Island</option>
    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
    <option value="Norway">Norway</option>
    <option value="Oman">Oman</option>
    <option value="Pakistan">Pakistan</option>
    <option value="Palau">Palau</option>
    <option value="Palestinian Territory, Occupied">Palestine</option>
    <option value="Panama">Panama</option>
    <option value="Papua New Guinea">Papua New Guinea</option>
    <option value="Paraguay">Paraguay</option>
    <option value="Peru">Peru</option>
    <option value="Philippines">Philippines</option>
    <option value="Pitcairn">Pitcairn Islands</option>
    <option value="Poland">Poland</option>
    <option value="Portugal">Portugal</option>
    <option value="Puerto Rico">Puerto Rico</option>
    <option value="Qatar">Qatar</option>
    <option value="Reunion">Réunion</option>
    <option value="Romania">Romania</option>
    <option value="Russian Federation">Russia</option>
    <option value="Rwanda">Rwanda</option>
    <option value="Saint Barthelemy">St. Barthélemy</option>
    <option value="Saint Helena">St. Helena</option>
    <option value="Saint Kitts and Nevis">St. Kitts & Nevis</option>
    <option value="Saint Lucia">St. Lucia</option>
    <option value="Saint Martin">St. Martin</option>
    <option value="Saint Pierre and Miquelon">St. Pierre & Miquelon</option>
    <option value="Saint Vincent and the Grenadines">St. Vincent & Grenadines</option>
    <option value="Samoa">Samoa</option>
    <option value="San Marino">San Marino</option>
    <option value="Sao Tome and Principe">São Tomé & Príncipe</option>
    <option value="Saudi Arabia">Saudi Arabia</option>
    <option value="Senegal">Senegal</option>
    <option value="Serbia">Serbia</option>
    <option value="Serbia and Montenegro">Serbia</option>
    <option value="Seychelles">Seychelles</option>
    <option value="Sierra Leone">Sierra Leone</option>
    <option value="Singapore">Singapore</option>
    <option value="Sint Maarten">Sint Maarten</option>
    <option value="Slovakia">Slovakia</option>
    <option value="Slovenia">Slovenia</option>
    <option value="Solomon Islands">Solomon Islands</option>
    <option value="Somalia">Somalia</option>
    <option value="South Africa">South Africa</option>
    <option value="South Georgia and the South Sandwich Islands">South Georgia & South Sandwich Islands</option>
    <option value="South Sudan">South Sudan</option>
    <option value="Spain">Spain</option>
    <option value="Sri Lanka">Sri Lanka</option>
    <option value="Sudan">Sudan</option>
    <option value="Suriname">Suriname</option>
    <option value="Svalbard and Jan Mayen">Svalbard & Jan Mayen</option>
    <option value="Swaziland">Eswatini</option>
    <option value="Sweden">Sweden</option>
    <option value="Switzerland">Switzerland</option>
    <option value="Syrian Arab Republic">Syria</option>
    <option value="Taiwan, Province of China">Taiwan</option>
    <option value="Tajikistan">Tajikistan</option>
    <option value="Tanzania, United Republic of">Tanzania</option>
    <option value="Thailand">Thailand</option>
    <option value="Timor-Leste">Timor-Leste</option>
    <option value="Togo">Togo</option>
    <option value="Tokelau">Tokelau</option>
    <option value="Tonga">Tonga</option>
    <option value="Trinidad and Tobago">Trinidad & Tobago</option>
    <option value="Tunisia">Tunisia</option>
    <option value="Turkey">Turkey</option>
    <option value="Turkmenistan">Turkmenistan</option>
    <option value="Turks and Caicos Islands">Turks & Caicos Islands</option>
    <option value="Tuvalu">Tuvalu</option>
    <option value="Uganda">Uganda</option>
    <option value="Ukraine">Ukraine</option>
    <option value="United Arab Emirates">United Arab Emirates</option>
    <option value="United Kingdom">United Kingdom</option>
    <option value="United States">United States</option>
    <option value="United States Minor Outlying Islands">U.S. Outlying Islands</option>
    <option value="Uruguay">Uruguay</option>
    <option value="Uzbekistan">Uzbekistan</option>
    <option value="Vanuatu">Vanuatu</option>
    <option value="Venezuela">Venezuela</option>
    <option value="Viet Nam">Vietnam</option>
    <option value="Virgin Islands, British">British Virgin Islands</option>
    <option value="Virgin Islands, U.s.">U.S. Virgin Islands</option>
    <option value="Wallis and Futuna">Wallis & Futuna</option>
    <option value="Western Sahara">Western Sahara</option>
    <option value="Yemen">Yemen</option>
    <option value="Zambia">Zambia</option>
    <option value="Zimbabwe">Zimbabwe</option>
      </select>
    </div>
    <div className="munyori-half">
      <label htmlFor="interest"><b>Interest</b></label>
      <select name="interest" value={interest} onChange={(e) => setInterest(e.target.value)} required>
      <option value="">Select Interest</option>
      <option value="Art">Art</option>
<option value="Baking">Baking</option>
<option value="Basketball">Basketball</option>
<option value="Blogging">Blogging</option>
<option value="Board Games">Board Games</option>
<option value="Books">Books</option>
<option value="Camping">Camping</option>
<option value="Cars">Cars</option>
<option value="Cooking">Cooking</option>
<option value="Crafts">Crafts</option>
<option value="Dancing">Dancing</option>
<option value="Drawing">Drawing</option>
<option value="Fashion">Fashion</option>
<option value="Fishing">Fishing</option>
<option value="Football">Football</option>
<option value="Gaming">Gaming</option>
<option value="Gardening">Gardening</option>
<option value="Hiking">Hiking</option>
<option value="History">History</option>
<option value="Hockey">Hockey</option>
<option value="Horror">Horror</option>
<option value="Investing">Investing</option>
<option value="Jewelry Making">Jewelry Making</option>
<option value="Journaling">Journaling</option>
<option value="Knitting">Knitting</option>
<option value="Languages">Languages</option>
<option value="Meditation">Meditation</option>
<option value="Movies">Movies</option>
<option value="Music">Music</option>
<option value="Nature">Nature</option>
<option value="Painting">Painting</option>
<option value="Photography">Photography</option>
<option value="Podcasting">Podcasting</option>
<option value="Politics">Politics</option>
<option value="Reading">Reading</option>
<option value="Running">Running</option>
<option value="Sculpting">Sculpting</option>
<option value="Sewing">Sewing</option>
<option value="Singing">Singing</option>
<option value="Skateboarding">Skateboarding</option>
<option value="Skiing">Skiing</option>
<option value="Soccer">Soccer</option>
<option value="Swimming">Swimming</option>
<option value="Table Tennis">Table Tennis</option>
<option value="Tennis">Tennis</option>
<option value="Travel">Travel</option>
<option value="Video Editing">Video Editing</option>
<option value="Volleyball">Volleyball</option>
<option value="Web Design">Web Design</option>
<option value="Weightlifting">Weightlifting</option>
<option value="Writing">Writing</option>
<option value="Yoga">Yoga</option>
<option value="Animals">Animals</option>
<option value="Architecture">Architecture</option>
<option value="Astrology">Astrology</option>
<option value="Basket Weaving">Basket Weaving</option>
<option value="Beer Tasting">Beer Tasting</option>
<option value="Bird Watching">Bird Watching</option>
<option value="Calligraphy">Calligraphy</option>
<option value="Chess">Chess</option>
<option value="Coffee">Coffee</option>
<option value="Collecting">Collecting</option>
<option value="Comedy">Comedy</option>
<option value="Conspiracy Theories">Conspiracy Theories</option>
<option value="Cosplay">Cosplay</option>
<option value="Cycling">Cycling</option>
<option value="Diving">Diving</option>
<option value="DIY">DIY</option>
<option value="Eco-Friendly Living">Eco-Friendly Living</option>
<option value="Farming">Farming</option>
<option value="Fitness">Fitness</option>
<option value="Food">Food</option>
<option value="Golf">Golf</option>
<option value="Graphic Design">Graphic Design</option>
<option value="Hair Styling">Hair Styling</option>
<option value="Home Improvement">Home Improvement</option>
<option value="Interior Design">Interior Design</option>
<option value="Jogging">Jogging</option>
<option value="Juicing">Juicing</option>
<option value="Kayaking">Kayaking</option>
<option value="Lacrosse">Lacrosse</option>
<option value="Magic">Magic</option>
<option value="Makeup">Makeup</option>
<option value="Martial Arts">Martial Arts</option>
<option value="Motorcycles">Motorcycles</option>
<option value="Mountain Biking">Mountain Biking</option>
<option value="Musical Instruments">Musical Instruments</option>
<option value="Nail Art">Nail Art</option>
<option value="Online Shopping">Online Shopping</option>
<option value="Organic Living">Organic Living</option>
<option value="Paddleboarding">Paddleboarding</option>
<option value="Paragliding">Paragliding</option>
<option value="Paranormal">Paranormal</option>
<option value="Pilates">Pilates</option>
<option value="Poetry">Poetry</option>
<option value="Pottery">Pottery</option>
<option value="Quilting">Quilting</option>
<option value="Rafting">Rafting</option>
<option value="Rock Climbing">Rock Climbing</option>
<option value="Scuba Diving">Scuba Diving</option>
<option value="Self-Improvement">Self-Improvement</option>
<option value="Shopping">Shopping</option>
<option value="Skincare">Skincare</option>
<option value="Snowboarding">Snowboarding</option>
<option value="Soap Making">Soap Making</option>
<option value="Social Media">Social Media</option>
<option value="Spirituality">Spirituality</option>
<option value="Stand-Up Comedy">Stand-Up Comedy</option>
<option value="Surfing">Surfing</option>
<option value="Tea">Tea</option>
<option value="Technology">Technology</option>
<option value="Theater">Theater</option>
<option value="Thrill-Seeking">Thrill-Seeking</option>
<option value="Trains">Trains</option>
<option value="Urban Exploration">Urban Exploration</option>
<option value="Vintage Clothing">Vintage Clothing</option>
<option value="Wine Tasting">Wine Tasting</option>
<option value="Woodworking">Woodworking</option>
<option value="Wrestling">Wrestling</option>
      </select>
    </div>
  </div>
  <button type="submit">SIGNUP</button>
  <div className="munyori-row-padding">
    <div className="munyori-half">
      <button onClick={ handlePop } type="button" className="munyori-pale-red">RESET PASSWORD</button>
    </div>
    <div className="munyori-half">
      <button onClick={ handleAuth } type="button" className="munyori-pale-green">LOGIN</button>
    </div>
  </div>
</form>
  }
{pop
? <div className="munyori-modal" style={{display: "block"}}>
<div className="munyori-modal-content" style={{borderRadius: "10px"}}>
<div className="munyori-content munyori-card munyori-padding" style={{borderRadius: "10px"}}>
<h4>RESET PASSWORD <i onClick={ handlePop }  className="fas fa-times munyori-right munyori-text-red munyori-large" style={{cursor: "pointer"}}></i></h4>
<hr />
<form onSubmit={ handleResetPassword}>
<label htmlFor="email"><b>Email</b></label>
<input type="email" placeholder="Enter Email" name="password" value={email} onChange={(e) => setEmail(e.target.value)} required />
<br />
<button type="submit">REQUEST RESET LINK</button>
		</form>
</div>
</div>
</div>
: <></>
}
</div>
}
</>
}
</div>
: <div className="munyori-twothird munyori-center"><h1>You're Already Logged In!</h1></div>
}
<SideBar />
</div>
<br />
</div>
<Top />
</div>
  );
};

export default Auth;