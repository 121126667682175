import React, { useState, useEffect, useRef } from 'react';
import {Helmet} from "react-helmet";
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import Greeting from '../includes/greeting';
import API from '../../jsx/backend';
import Icon from '../../img/icon.png';
import SideBar from '../sections/sidebar';
import moment from 'moment';
import Touring from '../sections/tour';
import image from '../../img/bg.jpg';
import Faq from '../sections/faq';

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [retrieve, setRetrieve] = useState(false);
    const auth = sessionStorage.getItem('token');
    const [question, setQuestion] = useState('');
    const name = sessionStorage.getItem('name');
    const [instruction, setInstruction] = useState(sessionStorage.getItem('instruction'));
    const [messageError, setMessageErr] = useState('');
    const messagesEndRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [history, setHistory] = useState([]);
    const [editable, setEditable] = useState(false);

    const instruct = "Hello " + name + ", explain what you want me to write..."

    const handleEditClick = () => {
      setEditable(true);
    };
  
    const handleSaveClick = () => {
      setEditable(false);
    };

    const handleUp = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, [messages], [history]);

        useEffect(() => {
          setLoading(true);
          const historyFormData = new FormData();    
          historyFormData.append("token", auth); 
          API.post(`/query/history/`, historyFormData)
          .then((response) => {
            setStatus(response.data.status);
            setHistory(response.data.answer);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          })
        }, []);

      const handleQuestion = (e) => {
        e.preventDefault();
        setLoading(true);
        setQuestion('');
        const creatorFormData = new FormData();
        creatorFormData.append("question",encodeURIComponent(question));     
        creatorFormData.append("instruction",encodeURIComponent(instruction)); 
        creatorFormData.append("token", auth);  
        API.post(`/query/data/`, creatorFormData)
        .then((response) => {
                  setMessages([...messages, response.data]);
                  setRetrieve(response.data.status);
                  setMessageErr(response.data.answer);
                  setLoading(false);
                })
              .catch((error) => {
                setLoading(false);
                })}
                
                function handleSettingsClick() {
                  setShowSettings(!showSettings);
                }
                function handleSettingsClose() {
                  setShowSettings(false);
                }
                const [showSettings, setShowSettings] = useState(false);

return (
<div  style={{minHeight: "95VH", paddingTop: "60px", wordWrap : "break-word"}}>
<Helmet>
<title>AI Creative Writer -  Munyori</title>
</Helmet>
<div className="munyori-section">
<div className="munyori-container munyori-row-padding">
<div className="munyori-twothird">
{auth
? <>
<Touring />
<div className="munyori-container chat-box">
<div class="munyori-row munyori-card munyori-sand munyori-padding munyori-small munyori-hide-small munyori-hide-medium" style={{position: "sticky", top: "0px", borderRadius: "3px"}}>
  <div class="munyori-col s6">
  <p className="munyori-left status"><img src={ Icon } alt="M" style={{width: "25px", height: "25px", borderRadius: "50%", background: "white"}} /> MUNYORI</p>
  </div>
  <div class="munyori-col s6">
  <p className="munyori-right">
{loading
  ? <span><i>TYPING</i></span>
  : <span><Greeting /></span>
} </p> </div>
</div>
<ul ref={messagesEndRef} className="munyori-justify chat-messages">
{history.length && status? (
  history.map((his) => (
  <li key={ his.reference }>
  <br />
  <div className="munyori-padding munyori-border" style={{borderRadius: "10px"}}>
  <Link to={`/chats/${his.reference}/`} style={{cursor: "pointer", textDecoration: "none"}} className='munyori-small'><i className="fas fa-envelope"></i> MESSAGE <strong className="munyori-right">{ moment(his.date).format('MMMM Do YYYY, h:mm') }</strong></Link>
  <hr />
  <div style={{textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: his.question.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/(^|\n)(#+)(.*)/g, (match, p1, p2, p3) => `${p1}<h${p2.length}>${p3.trim()}</h${p2.length}>`).replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\*(.*?)\*/g, '<em>$1</em>').replace(/\n?>\s?(.*)/g, '<blockquote>$1</blockquote>') }}></div>
</div>
<br />
 <div contentEditable={editable}
      onClick={ handleEditClick}
      onBlur={handleSaveClick}
      dangerouslySetInnerHTML={{ __html: his.response.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/(^|\n)(#+)(.*)/g, (match, p1, p2, p3) => `${p1}<h${p2.length}>${p3.trim()}</h${p2.length}>`).replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\*(.*?)\*/g, '<em>$1</em>').replace(/\n?>\s?(.*)/g, '<blockquote>$1</blockquote>') }}></div>
  <br />
  </li>
    ))) : (
<li className="munyori-display-container" style={{ width: "100%", zIndex: "0" }}>
{messages.length
? <></>
: <div className="munyori-display-content munyori-display middle munyori-center">
      <h1>Start Writing Today & Let Your Imagination Run Wild!</h1>
      <p>Unleash your creativity and write anything you want without the fear of plagiarism! From stories and songs to poems and assignments, we've got you covered. Plus, with our advanced tools, you can even tackle subjects like maths and physics with ease. Start writing today and let your imagination run wild!</p>
      <i className="fas fa-arrow-down fa-10x bounce munyori-text-grey"></i>
    </div>
 }
</li>
  )}
{retrieve
? <>
 {messages.map((data) => (
  <li key={ data.reference }>
  <br />
  <div className="munyori-padding munyori-border" style={{borderRadius: "10px"}}>
  <Link to={`/chat/${data.reference}/`} style={{cursor: "pointer", textDecoration: "none"}} className='munyori-small'><i className="fas fa-envelope"></i> MESSAGE <strong className="munyori-right">{ moment(data.date).format('MMMM Do YYYY, h:mm') }</strong></Link>
  <hr />
  <div style={{textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: data.question.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/(^|\n)(#+)(.*)/g, (match, p1, p2, p3) => `${p1}<h${p2.length}>${p3.trim()}</h${p2.length}>`).replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\*(.*?)\*/g, '<em>$1</em>').replace(/\n?>\s?(.*)/g, '<blockquote>$1</blockquote>') }}></div>
</div>
<br />
 <div contentEditable={editable}
      onClick={ handleEditClick}
      onBlur={handleSaveClick}
      dangerouslySetInnerHTML={{ __html: data.answer.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/\n/g, '<br />').replace(/(^|\n)(#+)(.*)/g, (match, p1, p2, p3) => `${p1}<h${p2.length}>${p3.trim()}</h${p2.length}>`).replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\*(.*?)\*/g, '<em>$1</em>').replace(/\n?>\s?(.*)/g, '<blockquote>$1</blockquote>') }}></div>
  <br />
  </li>
    ))}
</>
: <>{ messageError }</>
  }
</ul>
</div>
<br />
<div className="munyori-container munyori-white" style={{position: "sticky", bottom: "0px"}}>
<br />
<div class="input-container" onClick={ handleUp }>
<form onSubmit={ handleQuestion } className="input-box" style={{zIndex: "0"}}>
  <button type="button" class="settings-button" onClick={handleSettingsClick}>
    <i class="fas fa-cog"></i>
  </button>
  <input className="question" type="text" placeholder={ instruct }value={question} onChange={(e) => setQuestion(e.target.value)} required/>
  <button type="submit" class="send submit-button">
    {loading
  ? <span>WAIT</span>
  : <span>SEND</span>
}
</button>
</form>
</div>
<div class="settings-modal" style={{ display: showSettings ? 'block' : 'none' }}>
<h4>CHAT INSTRUCTIONS <i onClick={ handleSettingsClose } title="Save"  className="fas fa-check-circle munyori-right munyori-text-green fa-2x" style={{cursor: "pointer"}}></i></h4>
<hr />
<textarea value={instruction} onChange={(e) => setInstruction(e.target.value)} className="munyori-input munyori-border-0 munyori-light-grey" style={{borderRadius: "15px", minWidth: "100%", maxHeight: "200px", minHeight: "100px", maxWidth: "100%"}} placeholder="Enter instructions to keep the system focused on your topic and outline..." required>{ instruction}</textarea>
<br />
</div>
<br />
</div>
</>
: <div>
<div clasName="munyori-container munyori-display-container">
<div className="munyori-display-content" style={{borderRadius: "10px", backgroundImage: 'url(' + image + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>
   <div className="munyori-center munyori-padding" style={{backgroundColor: "#a7cc96e3", borderRadius: "10px"}}>   
      <h1 ref={messagesEndRef}>Start Writing Today <br />& <br /> Let Your Imagination Run Wild!</h1>
      <p>Unleash your creativity and write anything you want without the fear of plagiarism! From stories and songs to poems and assignments, we've got you covered. Plus, with our advanced tools, you can even tackle subjects like maths and physics with ease. Start writing today and let your imagination run wild!</p>
      <p>It costs $0.05 only per message! Contact +263 77 874 0934 for information.</p>
      <i className="fas fa-arrow-down fa-10x bounce munyori-text-grey"></i>
      <br />
      <Link to="/auth/"><button className='munyori-button munyori-green' style={{borderRadius: "30px"}}>GET STARTED WITH FREE CREDIT</button></Link>
    </div>
    </div>
</div>
<br />
<br />
<div clasName="munyori-container">
<Faq />
</div>
</div> 
}
</div>
<SideBar />
</div>
<br />
</div>
<Top />
</div>
);}

export default Home;