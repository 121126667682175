import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../includes/loader';
import Error from '../includes/error';
import API from '../../jsx/backend';
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import Capitalise from '../includes/capitalise';

const Chats = () => {


return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Chats - Munyori</title>
</Helmet>


<Top />
</div>
);}

export default Chats;