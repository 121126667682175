import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Top from '../includes/topping';

const Agreements = () => {

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Agreements - Munyori</title>
</Helmet>


<Top />
</div>
);}

export default Agreements;