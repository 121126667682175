
import React, { useState } from 'react';
import Tour  from 'reactour';

const Touring = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const steps = [
    {
          selector: '.settings-button',
          content: 'Use this option to add chat instructions and keep Munyori focused e.g an outline of your content or keywords to remember.',
        },{
        selector: '.question',
        content: 'Enter your question here, make sure to include all necessary details to save your credits in multiple messages, you can add examples.',
      },
  ];

  return (
    <Tour
    steps={steps}
    isOpen={isTourOpen}
    onRequestClose={() => setIsTourOpen(false)}
    startAt={0}
    accentColor="red"
/>
  );
};

export default Touring;