import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../includes/loader';
import Error from '../includes/error';
import API from '../../jsx/backend';
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import Capitalise from '../includes/capitalise';
import SideBar from '../sections/sidebar';
import Faq from '../sections/faq';

const Settings = () => {


return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Account Settings - Munyori</title>
</Helmet>
<div className="munyori-section">
<div className="munyori-container munyori-row-padding">
<div className="munyori-twothird">
<div className="munyori-container munyori-justify">
<h1 className="munyori-center diagonal-split-background munyori-padding" style={{borderRadius: "10px"}}>Munyori offers a wide range of customization options to ensure that your content is tailored to your specific needs. This includes the ability to save key words and branding information, as well as the option to request a specific writing style or tone.</h1>
<p>At Munyori, we understand the importance of consistent branding throughout your content. That's why we offer the option to save all necessary details in advance, including your company branding, so that our system can seamlessly incorporate them where each part is needed. This ensures that your content is not only high-quality and engaging, but also reflects your unique brand identity. So, you can rest assured that your content will always be on-brand and consistent, without the need for any manual replacements.</p>
<p>We also offer the option to add specific instructions such as outline, tone, orientation, and more through the chat instruction setting on the input area. This ensures that all content is tailored to your specific needs and preferences. Our advanced writer, Munyori, will incorporate these instructions into the content to deliver high-quality and engaging material that meets your exact specifications. If you have any further questions or would like to learn more about our customization options, please don't hesitate to contact us.</p>
</div>
<div clasName="munyori-container">
<Faq />
</div>
</div>
<SideBar />
</div>
<br />
</div>
<Top />
</div>
);}

export default Settings;