import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import API from '../../jsx/backend';
import Loader from '../includes/loader';
import { Link } from 'react-router-dom';

const Footer = () => {
    const urlParams = queryString.parse(window.location.search);
    const verifyValue = urlParams.verify;
    const [pop, setPop] = useState(false);
    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('Unprocessed');

    useEffect(() => {
        if (!window.sessionStorage.getItem('token')) {
            if (verifyValue) {
        setPop(true);
        const verifyFormDataNew = new FormData();
        verifyFormDataNew.append("verify", verifyValue);
        API.post(`/query/verify/`, verifyFormDataNew)
        .then((response) => {
            setSuccess(response.data.status);
            setDone(true)
            setMessage(response.data.message)
          })
      .catch((error) => {
      setDone(true);
      })
    }
}
}, []);

const reset = () => {
    setDone(false);
    setPop(false);
  };

return (
<footer className="foot" >
{pop
? <div className="munyori-modal" style={{display: "block"}}>
<div className="munyori-modal-content munyori-transparent" style={{borderRadius: "10px", maxWidth: "500px"}}>
<div className="munyori-content munyori-transparent munyori-padding" style={{borderRadius: "10px"}}>
{done
? <>
{success
? <div className="munyori-pale-green munyori-padding munyori-center" style={{borderRadius: "10px"}}>
 <p>{ message }</p>
 <br />
<Link onClick={ reset } to="/" className="munyori-btn munyori-circle munyori-padding munyori-red" style={{textDecoration: "none"}}>OK</Link> 
</div>
: <div className="munyori-pale-red munyori-padding munyori-center" style={{borderRadius: "10px"}}>
<p>{ message }</p>
<br />
<Link onClick={ reset } to="/" className="munyori-btn munyori-circle munyori-padding munyori-red" style={{textDecoration: "none"}}>OK</Link>
</div>
}
</>
: <div className="munyori-white munyori-card munyori-padding munyori-center" style={{borderRadius: "10px"}}>
<h4>VERIFYING ACCOUNT</h4>
<hr />
<Loader />
</div>
}
</div>
</div>
</div>
: <></>
}
</footer>
)};

export default Footer;