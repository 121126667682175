import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.png';

function SideBar() {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 80){
    setVisible(true)
    } else if (scrolled <= 80){
    setVisible(false)
    }};
    const scrollToTop = () =>{
    window.scrollTo({
    top: 0, 
    behavior: 'smooth'
    });};
    window.addEventListener('scroll', toggleVisible);
return (
<div className="legal munyori-third munyori-hide-small" style={{top: "70px", position: "sticky"}}>
<div className="munyori-card munyori-padding" style={{borderRadius: "10px"}}>
<h5><Link to={"/"} className="logo">
<img src={ logo } alt="Munyori" style={{height: "auto", width: "100px"}}/>
</Link> <span className="munyori-right">DISCLAIMER</span></h5>
<hr />
<div className="munyori-justify">
<p>The purpose of Munyori is to provide assistance in creative AI writing and aid in the production of high-quality content. However, Munyori cannot be held liable for any damages resulting from the use of its services. In addition, Munyori refuses requests related to violence, sexuality, self-harm, offensive, or highly controversial content. By using Munyori, you agree to these <Link to={`/agreements/`} style={{textDecoration: "none", fontWeight: "600"}}>agreements</Link> and acknowledge that the ultimate responsibility for the content produced lies with you. For additional information, please call us on +263 77 874 0934 or email support@munyori.co.zw.</p>
</div>
<hr />
<p className="munyori-center">&copy;{new Date().getFullYear()} Munyori - All Rights Reserved</p>
</div>
<br />
<center>
<button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} className="munyori-scroll-up munyori-transparent munyori-hover-orange munyori-btn munyori-circle" title="Scroll Up"><i className="fas fa-arrow-up"></i></button>
</center>
</div>
);}

export default SideBar;