import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Top from '../includes/topping';
import Loader from '../includes/loader';
import API from '../../jsx/backend';
import Error from '../includes/error';
import Greeting from '../includes/greeting';
import { Link } from 'react-router-dom';
import SideBar from '../sections/sidebar';
import Faq from '../sections/faq';

const Credits = () => {
 const auth = sessionStorage.getItem('token');
 const [voucher, setVoucher] = useState('');
 const [submitted, setSubmitted] = useState(false);
 const [error, setError] = useState(false);
 const [loading, setLoading] = useState(false);

 const handleSubmit = (event) => {
   event.preventDefault();
   if (voucher.length === 16) {
    setLoading(true);
    const purchaseFormData = new FormData();
    purchaseFormData.append("voucher", voucher);
    purchaseFormData.append("token", auth);
    
    API.post(`/query/recharge/`, purchaseFormData)
    .then((response) => {
        
     setLoading(false);
     setSubmitted(true);
     setError(false);
    })
    .catch((error) => { 
    setLoading(false);
     })} else {
     setSubmitted(true);
     setError(true);
   }
 }

 
return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Recharge Account - Munyori</title>
</Helmet>

<div className="munyori-section">
<div className="munyori-container munyori-row-padding">
<div className="munyori-twothird">
{auth
?
<>
<div className="munyori-container">
<br />
<div class="input-container">
<form onSubmit={ handleSubmit } className="input-box" style={{zIndex: "0"}}>
  <button type="button" class="settings-button">
    <i class="fas fa-money-bill"></i>
  </button>
  <input className="recharge" type="text" pattern="[0-9]{16}" placeholder="Enter a valid 16-digit voucher number..." value={voucher} onChange={(e) => setVoucher(e.target.value)} required/>
  <button type="submit" class="send submit-button">RECHARGE</button>
</form>
</div>
<br />
</div>
{!loading
? <div className="munyori-container munyori-center">
<br />
<h1>Start Recharging</h1>
<br />
</div>
: <Loader />
}
</>    
: <div className="munyori-justify">
<h1 className='munyori-padding munyori-center diagonal-split-background' style={{borderRadius: "10px"}}>You have two options to top up your account balance: you can either use your card to make a payment or purchase a prepaid voucher and redeem it by entering a 16-digit code.</h1>
 <p>Our vouchers are available through selected agents, and we recommend that you contact us if you wish to verify your agent before making any payment. It's important to note that you must redeem the voucher before leaving the seller's office to ensure that your account is credited with the purchased amount. This helps to avoid any inconvenience or delays in accessing our services. If you have any questions or concerns about purchasing credit vouchers, please don't hesitate to reach out to us. We're always here to help.</p>
<br />
<Faq />
</div>
}
</div>
<SideBar />
</div>
<br />
</div>
<Top />
</div>
);}

export default Credits;