import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, redirect } from "react-router-dom";
import Header from "../web/sections/header";
import Footer from "../web/sections/footer";

import Home from "../web/pages/home";
import Recharge from "../web/pages/recharge";
import Chats from "../web/pages/chats";
import Message from "../web/pages/message";
import Settings from "../web/pages/settings";
import Admin from "../web/pages/admin/admin";
import Users from "../web/pages/admin/users";
import Payments from "../web/pages/admin/payments";
import User from "../web/pages/admin/user";
import Collected from "../web/pages/admin/collected";
import Agreements from "../web/pages/agreements";
import FourOFour from "../web/pages/404";
import Auth from "../web/pages/auth";

class App extends Component {
render() {
return (
<Router>
<Header />
<Routes>
<Route exact path="/" element={<Home/>} />
<Route exact path="/recharge/" element={<Recharge/>} />
<Route exact path="/chats/" element={<Chats/>} />
<Route exact path="/chats/:message/" element={<Message/>} />
<Route exact path="/settings/" element={<Settings/>} />
<Route exact path="/auth/" element={<Auth />}></Route>
<Route exact path="/admin/" element={<Admin/>} />
<Route exact path="/admin/users/" element={<Users/>} />
<Route exact path="/admin/payments/" element={<Payments/>} />
<Route exact path="/admin/users/:user/" element={<User/>} />
<Route exact path="/admin/users/user/:messages/" element={<Collected/>} />
<Route exact path="/agreements/" element={<Agreements />} />
<Route path="*" element={<FourOFour/>} />
</Routes>
<Footer />
</Router>
);}}
 
export default App;