import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function Faq() {

return (
<div className="munyori-container">
<br />
<div className="munyori-heading munyori-opacity">
<h5><span>FREQUENTLY ASKED QUESTIONS</span></h5>
</div>
<Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What is Munyori?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    This creative writer is called Munyori, and it is an AI-powered writing assistant that creates unique and plagiarism-free content for any purpose. It works by analyzing user input to understand what kind of content is needed, and then uses AI algorithms to generate the desired content. Munyori is also capable of editing, proofreading, and refining existing content.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="b">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What makes Munyori Outstanding?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Munyori stands out from other writing bots because it offers a unique combination of quality, accuracy, and creativity. Our AI technology is designed to generate highly detailed and original content, with absolutely no plagiarism. We also provide a wide range of content, from articles to emails and poems. And, our AI is constantly learning, so you can be sure your content will always be up-to-date and relevant.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="c">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does it cost to use Munyori's services?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Munyori charges 1 credit per each message you receive, regardless of the type, quantity, or quality of the content you receive. One credit is equal to $0.05.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid="d">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I request a specific writing style or tone?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we can accommodate specific writing styles or tones to match your brand or personal preferences. We understand that every client has unique needs and preferences, and we are committed to delivering premium quality content that reflects your voice and style.
                    </p>
                    </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
</div>
);}

export default Faq;